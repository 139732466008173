//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ImageCopyright from '../Partials/ImageCopyright.vue';

export default {
    name: 'ContentTeaser',
    components: { ImageCopyright },
    props: ['content', 'ratio'],
    data() {
        return {
            imagePath: this.$config.pimcoreUrl,
        };
    },
    computed: {
        link() {
            return this.content?.link;
        },
        image() {
            let image = this.content?.image?.image;

            if (this.ratio === '16-9') {
                image = this.content?.imageWide?.image ?? image;
            }

            return this.$mapImageData(image);
        },
        imageWideMobile() {
            return this.$mapImageData(this.content?.imageWideMobile?.image);
        },
    },
};
